import { useEffect, useState } from 'react';
import masterCard from '@icons/mastercard.svg';
import { Row } from 'react-bootstrap';
import './WebView.scss';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axiosWrapper from '../../../../utils/api';
import { toast } from 'react-toastify';
import { format } from 'date-fns';

const WebView = () => {
    const [searchParams] = useSearchParams();
    const sessionId = searchParams.get('sessionId');
    const [sessionDetails, setSessionDetails] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    const fetchSessionDetails = async () => {
        try {
            if (!sessionId) {
                toast.error('Session ID is missing.');
                navigate('/parker/recent-sessions');
                return;
            }

            const url = `${import.meta.env.VITE_API_URL}/api/payment/session-details/${sessionId}`;
            const response = await axiosWrapper('get', url, {});

            if (!response.success) {
                throw new Error(response.message || 'Unable to fetch session details.');
            }

            setSessionDetails(response.data);
        } catch (error) {
            toast.error(error.message || 'An error occurred while fetching session details.');
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (sessionId) {
            fetchSessionDetails();
        }
    }, [sessionId]);

    if (isLoading) {
        return <p className="text-center mt-5">Loading session details...</p>;
    }

    if (!sessionDetails) {
        return <p className="text-center mt-5">Session details not found.</p>;
    }

    return (
        <div className="view-location-modal-body">
            <section className="view-location-content">
                <div className="heading-section">Visit Details</div>
                <Row className="data-section">
                    <div className="image-container-content">
                        <Row className="content-row">
                            <p className="title">License Plate # </p>
                            <p className="text-data">
                                {sessionDetails?.paymentData?.vehicle?.value?.licensePlatNumber || 'N/A'}
                            </p>
                        </Row>
                        <Row className="content-row">
                            <p className="title">Date In :</p>
                            <p className="text-data">{format(new Date(sessionDetails?.createdAt), 'MMMM dd, yyyy')}</p>
                        </Row>
                        <Row className="content-row">
                            <p className="title">Date Out :</p>
                            <p className="text-data">
                                {sessionDetails?.endTime
                                    ? format(new Date(sessionDetails?.endTime), 'MMMM dd, yyyy')
                                    : 'N/A'}
                            </p>
                        </Row>
                        <Row className="content-row">
                            <p className="title">Time In :</p>
                            <p className="text-data">{format(new Date(sessionDetails?.createdAt), 'hh:mm a')}</p>
                        </Row>
                        <Row className="content-row">
                            <p className="title">Time Out :</p>
                            <p className="text-data">
                                {sessionDetails?.endTime ? format(new Date(sessionDetails?.endTime), 'hh:mm a') : 'N/A'}
                            </p>
                        </Row>
                    </div>
                </Row>
                <div className="heading-section">Parking Charges</div>
                <Row className="data-section">
                    <div className="address-content">
                        <Row className="content-row">
                            <p className="title">Duration :</p>
                            <p className="text-data">${sessionDetails?.approvedMoney?.amount / 100 || 0}</p>
                        </Row>
                        <Row className="content-row">
                            <p className="title">Transaction Fee : </p>
                            <p className="text-data">${sessionDetails?.transactionFees?.toFixed(2) || '0'}</p>
                        </Row>
                        <Row className="content-row">
                            <p className="title">Sales Tax :</p>
                            <p className="text-data">${sessionDetails?.salesTax?.toFixed(2) || '0'}</p>
                        </Row>
                    </div>
                </Row>
                <div className="heading-section">Total</div>
                <Row className="data-section">
                    <div className="address-content">
                        <Row className="content-row">
                            <p className="title">Card Type :</p>
                            <div className="masterCard">
                                <img src={masterCard} alt="masterCard" />
                            </div>
                        </Row>
                        <Row className="content-row">
                            <p className="title">Last 4</p>
                            <p className="text-data">
                                <div
                                    style={{
                                        border: '1px solid #F0EFEF',
                                        borderRadius: '5px',
                                        padding: '3px 10px',
                                        width: '46px'
                                    }}
                                >
                                    {sessionDetails?.cardDetails?.card?.last4 || 'N/A'}
                                </div>
                            </p>
                        </Row>
                        <Row className="content-row">
                            <p className="title">Total :</p>
                            <p className="text-data">
                                {isNaN(
                                    parseFloat(sessionDetails?.totalMoney?.amount / 100) +
                                        parseFloat(sessionDetails?.salesTax)
                                )
                                    ? 'N/A'
                                    : `$${(
                                          parseFloat(sessionDetails?.totalMoney?.amount / 100) +
                                          parseFloat(sessionDetails?.salesTax)
                                      )?.toFixed(2)}`}
                            </p>
                        </Row>
                    </div>
                </Row>
            </section>
        </div>
    );
};

export default WebView;
