import { useEffect } from 'react';
import { setSidebarState } from '@redux/sidebar/sidebar_slice';
import { useSelector, useDispatch } from 'react-redux';
import './mobilepanel.scss';
import { useLocation } from 'react-router-dom';

import * as types from '../../../redux/actions/actionTypes';

const MobilePanel = (props) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const noPadding = useSelector((state) => state.root.noPadding);
    const parkerHome = location?.pathname === '/parker' || location.pathname === '/parker/';

    const isLocationModule = location.pathname === '/parker/locations';

    const { transitionType, collapsedForm } = useSelector((state) => state.sidebar);

    useEffect(() => {
        const handleResize = () => {
            if (window.screen.width <= 768) {
                dispatch(
                    setSidebarState({
                        transitionType: 'hide/show',
                        collapsedForm: false,
                        hiddenForm: true
                    })
                );
                if (isLocationModule) {
                    dispatch({ type: types.ALL_RECORDS, data: { keyOfData: 'noPadding', data: 'no-padding' } });
                } else {
                    dispatch({ type: types.ALL_RECORDS, data: { keyOfData: 'noPadding', data: '' } });
                }
            } else if (window.screen.width > 768 && window.screen.width <= 992) {
                dispatch(
                    setSidebarState({
                        transitionType: 'collapse/expand',
                        collapsedForm: true,
                        hiddenForm: false
                    })
                );
                if (isLocationModule) {
                    dispatch({ type: types.ALL_RECORDS, data: { keyOfData: 'noPadding', data: 'no-padding' } });
                } else {
                    dispatch({ type: types.ALL_RECORDS, data: { keyOfData: 'noPadding', data: '' } });
                }
            } else {
                dispatch(
                    setSidebarState({
                        transitionType: 'collapse/expand',
                        collapsedForm: false,
                        hiddenForm: false
                    })
                );
                dispatch({ type: types.ALL_RECORDS, data: { keyOfData: 'noPadding', data: '' } });
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [isLocationModule]);

    // Conditionally build class names without using 'classnames' library
    const panelClasses = ['mobile-panel'];
    if (transitionType === 'collapse/expand') {
        panelClasses.push(collapsedForm ? 'grow-main-panel' : 'shrink-main-panel');
    }
    if (transitionType === 'hide/show') {
        panelClasses.push('hideorshow-transitiontype');
    }
    if (noPadding === 'no-padding') {
        panelClasses.push('no-padding');
    }

    const contentWrapperClasses = ['mobile-content-wrapper'];
    if (noPadding === 'no-padding') {
        contentWrapperClasses.push('no-padding');
    }

    return (
        <>
            {(transitionType === 'collapse/expand' || transitionType === 'hide/show') && (
                <div id="scrollable-div" className={panelClasses.join(' ')}>
                    <div className={`${contentWrapperClasses.join(' ')} ${parkerHome ? 'custom-top-parker-home' : ''}`}>
                        {props.children}
                    </div>
                </div>
            )}
        </>
    );
};

export default MobilePanel;
