import './SessionDetailMobile.scss';
import { Breadcrumb, Row } from 'react-bootstrap';
import masterCard from '@icons/mastercard.svg';
import { useNavigate, useSearchParams } from 'react-router-dom';
import visa from '@icons/visa.svg';
import paypal from '@icons/paypal.svg';
import amex from '@icons/amex.svg';
import { useEffect, useState } from 'react';
import { format } from 'date-fns';

import axiosWrapper from '../../utils/api';
import { toast } from 'react-toastify';

const SessionDetailMobile = () => {
    const [searchParams] = useSearchParams();
    const sessionId = searchParams.get('sessionId');

    const navigate = useNavigate();
    const [sessionDetails, setSessionDetails] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const cardIcons = {
        MASTERCARD: masterCard,
        VISA: visa,
        AMERICAN_EXPRESS: amex,
        PAYPAL: paypal
    };

    const fetchSessionDetails = async () => {
        try {
            const url = `${import.meta.env.VITE_API_URL}/api/payment/session-details/${sessionId}`;
            const response = await axiosWrapper('get', url);

            if (!response.success) {
                throw new Error(response.message || 'Unable to fetch session details.');
            }

            setSessionDetails(response.data);
        } catch (error) {
            toast.error(error.message || 'An error occurred while fetching session details.');
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (sessionId) {
            fetchSessionDetails();
        }
    }, [sessionId]);

    if (isLoading) {
        return <p className="text-center mt-5">Loading session details...</p>;
    }

    if (!sessionDetails) {
        return <p className="text-center mt-5">Session details not found.</p>;
    }

    return (
        <>
            <div className="breadcrumb-session">
                <Breadcrumb>
                    <Breadcrumb.Item onClick={() => navigate('/parker/')}>Recent Sessions</Breadcrumb.Item>
                    <Breadcrumb.Item active>Session Details</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <div className="session-mob-details">
                <div className="title-section">
                    <p>
                        Visited on <strong>{format(sessionDetails?.createdAt, 'MMMM dd, yyyy') || 'N/A'}</strong>
                    </p>
                    <h4>Zone {sessionDetails?.paymentData?.zone?.value || 'N/A'}</h4>
                    {sessionDetails?.location?.address && (
                        <p className="location-address">
                            {sessionDetails?.location?.address?.split(',')[0] || 'Address not available'},{' '}
                            {/* Extracted street address */}
                            {sessionDetails?.location?.city || 'N/A'}, {sessionDetails?.location?.state || 'N/A'}{' '}
                            {sessionDetails?.location?.zipCode || ''}
                        </p>
                    )}
                </div>
                <hr />
                <div className="heading-section mb-3">View Details</div>
                <Row className="data-section">
                    <div className="image-container-content">
                        <div className="content-row">
                            <p className="title">License Plate # </p>
                            <p className="text-data">
                                {sessionDetails?.paymentData?.vehicle?.value?.licensePlatNumber || 'N/A'}
                            </p>
                        </div>
                        <div className="content-row">
                            <p className="title">Date In :</p>
                            <p className="text-data">{format(sessionDetails?.createdAt, 'MMMM dd, yyyy') || 'N/A'}</p>
                        </div>
                        <div className="content-row">
                            <p className="title">Date Out :</p>
                            <p className="text-data">{format(sessionDetails?.endTime, 'MMMM dd, yyyy') || 'N/A'}</p>
                        </div>
                        <div className="content-row">
                            <p className="title">Time In :</p>
                            <p className="text-data">
                                {format(sessionDetails?.createdAt, 'hh:mm a').toLowerCase() || 'N/A'}
                            </p>
                        </div>
                        <div className="content-row">
                            <p className="title">Time Out :</p>
                            <p className="text-data">
                                {format(sessionDetails?.endTime, 'hh:mm a').toLowerCase() || 'N/A'}
                            </p>
                        </div>
                    </div>
                </Row>
                <hr />
                <div className="heading-section mb-3">Parking Charges</div>
                <Row className="data-section">
                    <div className="address-content">
                        <div className="content-row">
                            <p className="title">Duration </p>
                            <p className="text-data">${sessionDetails?.approvedMoney?.amount / 100 || 0}</p>
                        </div>
                        <div className="content-row">
                            <p className="title">Transaction Fee </p>
                            <p className="text-data">${sessionDetails?.transactionFees?.toFixed(2) || '0.00'}</p>
                        </div>
                        <div className="content-row">
                            <p className="title">Sales Tax </p>
                            <p className="text-data">${sessionDetails?.salesTax?.toFixed(2) || '0.00'}</p>
                        </div>
                    </div>
                </Row>
                <hr />
                <div className="heading-section mb-3">Total</div>
                <Row className="data-section">
                    <div className="address-content">
                        <div className="content-row">
                            <p className="title">Card Type </p>
                            <div className="d-flex justify-content-end w-100">
                                <div className="masterCard">
                                    <img
                                        src={
                                            cardIcons[sessionDetails?.cardDetails?.card?.cardBrand?.toUpperCase()] ||
                                            masterCard
                                        }
                                        alt="Card Icon"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="content-row">
                            <p className="title">Last 4</p>
                            <p className="text-data">
                                <div
                                    style={{
                                        border: '1px solid #F0EFEF',
                                        borderRadius: '5px',
                                        padding: '3px 10px',
                                        width: '46px',
                                        float: 'inline-end'
                                    }}
                                >
                                    {sessionDetails?.cardDetails?.card?.last4 || 'N/A'}
                                </div>
                            </p>
                        </div>
                        <div className="content-row">
                            <p className="title">Total </p>
                            <p className="text-data">
                                {isNaN(
                                    parseFloat(sessionDetails?.totalMoney?.amount / 100) +
                                        parseFloat(sessionDetails?.salesTax)
                                )
                                    ? 'N/A'
                                    : `$${(
                                          parseFloat(sessionDetails?.totalMoney?.amount / 100) +
                                          parseFloat(sessionDetails?.salesTax)
                                      )?.toFixed(2)}`}
                            </p>
                        </div>
                    </div>
                </Row>
            </div>
        </>
    );
};

export default SessionDetailMobile;
