// SessionDetails Component
import { useEffect, useState } from 'react';
import searchNormal from '@icons/search-icon.svg';
import './SessionDetails.scss';
import { Breadcrumb, Col, Row, Tab, Tabs } from 'react-bootstrap';
import Sessions from './Sessions/Sessions';
import ActiveSubscriptions from './ActiveSubscriptions/ActiveSubscriptions';
import PreviousSubscriptions from './PreviousSubscriptions/PreviousSubscriptions';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import debounce from 'lodash/debounce';
import axiosWrapper from '../../utils/api';

const SessionDetails = () => {
    const { userToken, userInfo } = useSelector((state) => state.auth);
    const [searchInput, setSearchInput] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredSessions, setFilteredSessions] = useState([]);
    const [page, setPage] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [key, setKey] = useState('Sessions');
    const isMobile = useMediaQuery({ query: '(max-width: 576px)' });
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    // Debounced function to set search query for API calls
    const debouncedSetSearchQuery = debounce((value) => {
        setSearchQuery(value);
    }, 300);

    const handleSearchChange = (e) => {
        const value = e.target.value;
        setSearchInput(value);
        debouncedSetSearchQuery(value);
    };

    const getAllSessions = async (currentPage) => {
        try {
            setLoading(true);
            const params = new URLSearchParams({
                search: searchQuery,
                page: (currentPage || page) + 1,
                limit: 9
            });
            const url = `${import.meta.env.VITE_API_URL}/api/payment/all?${params.toString()}`;
            const response = await axiosWrapper('get', url, null, userToken);

            if (!response.success) {
                throw new Error(response.message || 'Unable to fetch sessions');
            }

            const sessions = response?.data
                ?.filter(
                    (item) =>
                        item?.email === userInfo?.email ||
                        item?.phoneNumber.replace(/[^\d+]/g, '') === userInfo?.phoneNumber
                )
                ?.map((item) => {
                    let totalTime = '0m';

                    if (item?.paymentData) {
                        const duration = item?.paymentData?.duration;
                        totalTime = duration?.byHour
                            ? `${duration?.value?.hours}h ${duration?.value?.minutes}m`
                            : `${duration?.value?.days > 1 ? duration?.value?.days + ' Days' : duration?.value?.days + ' Day'}`;
                    }

                    return {
                        id: item?._id,
                        zone: item?.paymentData?.zone?.value || '',
                        plateNo: item?.paymentData?.vehicle?.value?.licensePlatNumber || '',
                        date: format(new Date(item.createdAt), 'MMMM dd'),
                        totalTime: totalTime,
                        status: item?.status === 'COMPLETED',
                        amount: item?.totalMoney?.amount,
                        totalMoney: item?.approvedMoney?.amount / 100,
                        location: item?.location || null
                    };
                });

            const { pagination } = response;

            setFilteredSessions(sessions || []);
            setTotalCount(pagination?.totalCount || 0);
        } catch (error) {
            toast.error(error.message || 'Failed to fetch sessions');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getAllSessions();

        return () => {
            debouncedSetSearchQuery.cancel(); // Cleanup debounce on unmount
        };
    }, [searchQuery, page]);

    return (
        <div className="session-details">
            {isMobile && (
                <div className="breadcrumb-custom mb-4">
                    <Breadcrumb>
                        <Breadcrumb.Item onClick={() => navigate('/parker')}>Recent Sessions</Breadcrumb.Item>
                        <Breadcrumb.Item active>Session Details</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            )}

            <Row className="align-items-center">
                <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                    <h4 className="activity-title">Activity</h4>
                </Col>
                <Col xs={12} sm={12} md={8} lg={8} xl={8} className="d-flex justify-content-end mt-3 mt-xl-0">
                    <div className="right-items">
                        <div className="search-input-container">
                            <input
                                type="text"
                                name="search"
                                placeholder="Search"
                                value={searchInput}
                                onChange={handleSearchChange}
                            />
                            <img src={searchNormal} alt="Search icon" className="search-icon" />
                        </div>
                    </div>
                </Col>
            </Row>
            <div className="tabs-header mt-4">
                <Tabs id="controlled-tab-example" activeKey={key} onSelect={(k) => setKey(k)}>
                    <Tab eventKey="Sessions" title="Sessions">
                        <Sessions
                            totalCount={totalCount}
                            sessionData={filteredSessions}
                            page={page}
                            setPage={setPage}
                            loading={loading}
                        />
                    </Tab>
                    <Tab eventKey="activeSubscriptions" title="Active Subscriptions">
                        <ActiveSubscriptions activeKey={key} />
                    </Tab>
                    <Tab eventKey="previousSubscriptions" title="Previous Subscriptions">
                        <PreviousSubscriptions />
                    </Tab>
                </Tabs>
            </div>
        </div>
    );
};

export default SessionDetails;
