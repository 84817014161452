import { useEffect, useState } from 'react';
import Slider from 'react-slick';
import './AvailableSubscriptions.scss';
import { Col, Row } from 'react-bootstrap';
import 'mapbox-gl/dist/mapbox-gl.css';
import axiosWrapper from '../../utils/api';
import { API_URL } from '../../utils/apiUrl';
import { useSelector } from 'react-redux';
import SubcriptionDetailModal from '../Modals/SubcriptionDetailModal/SubcriptionDetailModal';
import { toast } from 'react-toastify';
import TruncatedText from '../TextTruncate/TextTruncate';

// Custom arrow components
const CustomPrevArrow = (props) => {
    const { className, style, onClick, isDisabled } = props;
    return (
        <div
            className={className}
            style={{
                ...style,
                display: 'block',
                cursor: isDisabled ? 'not-allowed' : 'pointer',
                backgroundImage: "url('https://i.ibb.co/xMyLwVX/Vector.png')",
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                width: '20px',
                height: '20px',
                zIndex: 1
            }}
            onClick={isDisabled ? null : onClick}
        />
    );
};

const CustomNextArrow = (props) => {
    const { className, style, onClick, isDisabled } = props;
    return (
        <div
            className={className}
            style={{
                ...style,
                display: 'block',
                cursor: isDisabled ? 'not-allowed' : 'pointer',
                backgroundImage: "url('https://i.ibb.co/hfH2qrt/right-icon.png')",
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                width: '20px',
                height: '20px',
                zIndex: 1
            }}
            onClick={isDisabled ? null : onClick}
        />
    );
};

const AvailableSubscriptions = ({ isMobile, triggerAvailableSubscriptions }) => {
    const [availableLocations, setAvailableLocations] = useState([]);
    const { userToken } = useSelector((state) => state.auth);
    const [show, setShow] = useState(false);
    const [selectedLocationId, setSelectedLocationId] = useState(null);
    const [currentSlide, setCurrentSlide] = useState(0);
    const totalSlides = availableLocations.length;

    const fetchAvailableSubscriptions = async () => {
        try {
            const response = await axiosWrapper('GET', API_URL.GET_AVAILABLE_LOCATIONS, {}, userToken);
            setAvailableLocations(response.data);
        } catch (error) {
            toast.error(error.message);
        }
    };
    useEffect(() => {
        fetchAvailableSubscriptions();
    }, [triggerAvailableSubscriptions]);

    const slidesToShow = isMobile ? 1 : 3;

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: slidesToShow,
        slidesToScroll: 1,
        prevArrow: <CustomPrevArrow isDisabled={currentSlide === 0} />,
        nextArrow: <CustomNextArrow isDisabled={currentSlide >= totalSlides - slidesToShow} />,
        afterChange: (current) => setCurrentSlide(current), // Track current slide index
        responsive: [
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 1366,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            }
        ]
    };
    const handleClose = () => setShow(false);
    const handleSubscribe = (item) => {
        setShow(true);
        setSelectedLocationId(item?._id); // Use the unique identifier of the location
    };

    return (
        <div className="available-content">
            {isMobile ? (
                <div className="slide-item-mob">
                    <Row>
                        {availableLocations.length > 0 ? (
                            availableLocations?.map((item) => (
                                <Col key={item._id} xs={6} sm={6} className="mb-3">
                                    <div className="active-data">
                                        <div className="map-container">
                                            <img src={item?.image} alt="location" />
                                        </div>
                                        <Row className="mt-3 mt-sm-0 mt-md-0 mt-lg-0">
                                            <Col>
                                                <h4>{item?.zone}</h4>
                                            </Col>
                                        </Row>
                                        <Row className="mt-3 mt-sm-0 mt-md-0 mt-lg-0">
                                            <Col>
                                                <button
                                                    type="button"
                                                    className="manage-btn"
                                                    onClick={() => handleSubscribe(item)}
                                                >
                                                    Subscribe
                                                </button>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            ))
                        ) : (
                            <p className="text-center">No available subscriptions found.</p>
                        )}
                    </Row>
                </div>
            ) : (
                <>
                    {availableLocations.length === 0 ? (
                        <p className="text-center">No available subscriptions found.</p>
                    ) : (
                        <>
                            <Slider {...settings}>
                                {availableLocations?.map((item) => (
                                    <div key={item?._id} className="slide-item">
                                        <Row>
                                            <Col xs={12} sm={12}>
                                                <div className="active-data">
                                                    <div className="map-container">
                                                        <img src={item?.image} alt="location" />
                                                    </div>
                                                    <Row className="mt-3">
                                                        <Col>
                                                            <h6 className="mb-1">{item?.zone}</h6>
                                                            <TruncatedText
                                                                text={`${item?.address}, ${item?.addressType}, ${item.city}, ${item?.state}`}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row className="mt-3">
                                                        <Col>
                                                            <button
                                                                type="button"
                                                                className="manage-btn"
                                                                onClick={() => handleSubscribe(item)}
                                                            >
                                                                Subscribe
                                                            </button>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                ))}
                            </Slider>
                            {show && (
                                <SubcriptionDetailModal
                                    selectedLocationId={selectedLocationId}
                                    show={show}
                                    handleClose={handleClose}
                                />
                            )}
                        </>
                    )}
                </>
            )}
        </div>
    );
};

export default AvailableSubscriptions;
