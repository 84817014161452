const BASE_URL = import.meta.env.VITE_API_URL;
const PARKER_API_BASE = `${BASE_URL}/api/parker`;
const VEHICLE_API_BASE = `${BASE_URL}/api/vehicle`;
const LOCATION_API_BASE = `${BASE_URL}/api/locations`;
const SUBSCRIPTION_API_BASE = `${BASE_URL}/api/subscription`;
const CARD_API_BASE = `${BASE_URL}/api/card`;

export const API_URL = {
    // Parker API
    SEND_OTP: `${PARKER_API_BASE}/send-otp`,
    RESEND_OTP: `${PARKER_API_BASE}/resend-otp`,
    VERIFY_OTP: `${PARKER_API_BASE}/verify-otp`,
    REGISTER_DETAILS: `${PARKER_API_BASE}/register-details`,
    ADD_VEHICLE: `${PARKER_API_BASE}/add-vehicle`,
    LOGIN: `${PARKER_API_BASE}/login`,
    FORGOT_PASSWORD_SEND_OTP: `${PARKER_API_BASE}/forgot-password/send-otp`,
    FORGOT_PASSWORD_VERIFY_OTP: `${PARKER_API_BASE}/forgot-password/verify-otp`,
    RESET_PASSWORD: `${PARKER_API_BASE}/reset-password`,

    // Vehicles routes
    GET_VEHICLES: `${VEHICLE_API_BASE}/all`,
    CREATE_VEHICLE: `${VEHICLE_API_BASE}/create`,
    GET_VEHICLE_BY_ID: (id) => `${VEHICLE_API_BASE}/${id}`,
    UPDATE_VEHICLE: (id) => `${VEHICLE_API_BASE}/update/${id}`,
    DELETE_VEHICLE: (id) => `${VEHICLE_API_BASE}/delete/${id}`,

    // Locations routes
    GET_LOCATIONS: `${LOCATION_API_BASE}/get/all`,
    GET_LOCATION_BY_ID: (id) => `${LOCATION_API_BASE}/get/single/${id}`,
    GET_SINGLE_AVAILABLE_LOCATION: (id) => `${LOCATION_API_BASE}/get/available-location/${id}`,
    GET_LOCATION_BY_NEARBY_LOCATION_ID: (id) => `${LOCATION_API_BASE}/get/nearby/${id}`,
    CREATE_LOCATION: `${LOCATION_API_BASE}/create`,
    UPDATE_LOCATION: (id) => `${LOCATION_API_BASE}/update/${id}`,
    DELETE_LOCATION: (id) => `${LOCATION_API_BASE}/delete/${id}`,
    GET_NEARBY_LOCATIONS: `${LOCATION_API_BASE}/get/nearby`,
    GET_RECURRING_RATES: (id) => `${LOCATION_API_BASE}/get/recurring-rates/${id}`,
    GET_FILTERED_LOCATIONS: `${LOCATION_API_BASE}/get/filtered-locations`,
    GET_AVAILABLE_LOCATIONS: `${LOCATION_API_BASE}/get/available-for-subscription`,

    //Parkers Admin
    GET_ALL_PARKERS: `${PARKER_API_BASE}/all`,
    GET_PARKER_BY_ID: `${PARKER_API_BASE}`,
    DELETE_PARKER: `${PARKER_API_BASE}/delete`,
    UPDATE_PARKER_BY_ID: `${PARKER_API_BASE}/update`,

    // Account Settings Routes
    UPDATE_PROFILE: `${PARKER_API_BASE}/profile/update`,
    UPDATE_PASSWORD: `${PARKER_API_BASE}/password/update`,

    // Subscription routes
    PARKER_SUBSCRIPTION_FOR_ADMIN: `${SUBSCRIPTION_API_BASE}/parker-subscriptions-for-admin`,
    GET_PARKER_PAYMENTS_ADMIN: `${SUBSCRIPTION_API_BASE}/parker-payments`,
    CREATE_SUBSCRIPTION: `${SUBSCRIPTION_API_BASE}/create`,
    MANAGE_UPDATE_SUBSCRIPTION: `${SUBSCRIPTION_API_BASE}/manage-update-subscription`,
    GET_SUBSCRIPTION_BY_USER_ID: (id) => `${SUBSCRIPTION_API_BASE}/get/by-user/${id}`,
    UPDATE_SUBSCRIPTION: (id) => `${SUBSCRIPTION_API_BASE}/update/${id}`,
    DELETE_SUBSCRIPTION: (id) => `${SUBSCRIPTION_API_BASE}/delete/${id}`,
    GET_ACTIVE_SUBSCRIPTIONS: `${SUBSCRIPTION_API_BASE}/active`,
    GET_PREVIOUS_SUBSCRIPTIONS: `${SUBSCRIPTION_API_BASE}/previous`,
    // For fetching available locations for subscription
    CANCEL_SUBSCRIPTION: (id) => `${SUBSCRIPTION_API_BASE}/cancel/${id}`,
    GET_SUBSCRIPTION_TYPES: `${SUBSCRIPTION_API_BASE}/get/subscription-types`,
    GET_SUBSCRIPTION_BY_LOCATION_ID: (id) => `${SUBSCRIPTION_API_BASE}/get/by-location/${id}`,
    RE_SUBSCRIBE: (id) => `${SUBSCRIPTION_API_BASE}/re-subscribe/${id}`,
    GET_SUBSCRIPTION_BY_ID: (id) => `${SUBSCRIPTION_API_BASE}/${id}`,
    GET_SUBSCRIPTION_BY_USER_AND_LOCATION_IDS: (userId, locationId) =>
        `${SUBSCRIPTION_API_BASE}/get/by-user-and-location/${userId}/${locationId}`,

    // Card routes
    ADD_CARD: `${CARD_API_BASE}/add`,
    GET_CARDS: `${CARD_API_BASE}/list`,
    DELETE_CARD: (cardId) => `${CARD_API_BASE}/delete/${cardId}`,
    SET_DEFAULT_CARD: `${CARD_API_BASE}/default`,
    GET_DEFAULT_CARD: `${CARD_API_BASE}/get/default`
};
