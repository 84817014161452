import './ActiveSubscriptions.scss';
import AvailableSubscriptions from '../../AvailableSubscriptions/AvailableSubscriptions';
import ActiveSubcriptions from '../../SlideSubcriptions/ActiveSubcriptions';
import { useMediaQuery } from 'react-responsive';
import { useState } from 'react';

const ActiveSubscriptions = ({ key = 'activeSubscriptions' }) => {
    const isMobile = useMediaQuery({ query: '(max-width: 576px)' });
    const [triggerAvailableSubscriptions, setTriggerAvailableSubscriptions] = useState(false);

    return (
        <div className="active-subscription">
            <div className="session-title">
                <h4>Active Subscriptions</h4>
            </div>

            <div className="mt-4">
                <ActiveSubcriptions
                    activeTab={key}
                    setTriggerAvailableSubscriptions={setTriggerAvailableSubscriptions}
                    triggerAvailableSubscriptions={triggerAvailableSubscriptions}
                />
            </div>

            {!isMobile && (
                <>
                    <div className="session-title mt-4">
                        <h4>Available Subscriptions</h4>
                    </div>

                    <div className="mt-4">
                        <AvailableSubscriptions triggerAvailableSubscriptions={triggerAvailableSubscriptions} />
                    </div>
                </>
            )}
        </div>
    );
};

export default ActiveSubscriptions;
