import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { changeCollapsedForm, changeHiddenForm, changeActiveItem } from '@redux/sidebar/sidebar_slice';
import './ParkerSidebar.scss';

// import all static icons
import transactionIcon from '@icons/transaction-icon.svg';
import parkers from '@icons/parker-inactive.svg';
import reportIcon from '@icons/report-icon.svg';
import lprIcon from '@icons/lpr-icon.svg';
import logoutIcon from '@icons/logout-icon.svg';
import userCircle from '@icons/profile-circle.svg';
import redLogout from '@icons/logout-red.svg';
import locationIcon from '@icons/location-icon.svg';

import { useDispatch, useSelector } from 'react-redux';
// Import all light frame icons
import homeIcon from '@icons/Home Inactive.svg';
import parkerLocationIcon from '@icons/Locations Inactive.svg'; // Locations
import subscriptionIcon from '@icons/Subscriptions Inactive.svg'; // Subscriptions
import paymentIcon from '@icons/Payments Inactive.svg'; // Payments
import vehicleIcon from '@icons/Vehicles Inactive.svg'; // Vehicles
import supportIcon from '@icons/Support Inactive.svg'; // Support

import LogoutModal from '../LogoutModal/LogoutModal';
import SidebarItem from '../Dashboard/Sidebar/SidebarItem';
import { logoutUser } from '../../redux/auth/auth_slice';

const ParkerSidebar = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    // Redux states
    const { userInfo } = useSelector((state) => state.auth);
    const { transitionType, collapsedForm, hiddenForm, activeItem } = useSelector((state) => state.sidebar);

    // Local states
    const [showLogoutModal, setLogoutModal] = useState(false);
    const handleLogoutModal = () => setLogoutModal((prev) => !prev);

    const [sideBarItems, setSidebarItems] = useState([]);

    // Define admin items
    const adminSideBarItems = [
        {
            id: 1,
            name: 'Locations',
            iconLight: locationIcon,
            linkTo: '/admin/'
        },
        {
            id: 2,
            name: 'Transactions',
            iconLight: transactionIcon,
            linkTo: '/admin/transactions'
        },
        {
            id: 3,
            name: 'Parkers',
            iconLight: parkers,
            linkTo: '/admin/parkers'
        },
        {
            id: 4,
            name: 'Report',
            iconLight: reportIcon,
            linkTo: '/admin/reports'
        },
        {
            id: 5,
            name: 'LPR',
            iconLight: lprIcon,
            linkTo: '/admin/lpr'
        },
        {
            id: 6,
            name: 'Logout',
            iconLight: logoutIcon
        }
    ];

    // Define user items
    const userSideBarItems = [
        {
            id: 1,
            name: 'Home',
            iconLight: homeIcon,
            linkTo: '/parker/'
        },
        {
            id: 2,
            name: 'Locations',
            iconLight: parkerLocationIcon,
            linkTo: '/parker/locations'
        },
        {
            id: 3,
            name: 'Subscriptions',
            iconLight: subscriptionIcon,
            linkTo: '/parker/subscriptions'
        },
        {
            id: 4,
            name: 'Payments',
            iconLight: paymentIcon,
            linkTo: '/parker/payments'
        },
        {
            id: 5,
            name: 'Vehicles',
            iconLight: vehicleIcon,
            linkTo: '/parker/vehicles'
        },
        {
            id: 6,
            name: 'Support',
            iconLight: supportIcon,
            linkTo: '/parker/support'
        }
    ];

    // Sidebar item click handler
    const handleSideBarClick = (item) => {
        if (item.name === 'Logout') {
            handleLogoutModal();
            dispatch(changeActiveItem(1)); // Reset to default item or any other logic
        } else {
            dispatch(changeActiveItem(item.id));
            // If the sidebar is open on mobile, close it automatically after navigation
            if (window.screen.width < 768) {
                dispatch(changeHiddenForm(true));
            } else if (window.screen.width > 768 && window.screen.width < 992 && !collapsedForm) {
                dispatch(changeCollapsedForm(true));
            }
            navigate(item.linkTo);
        }
    };

    // Decide which items to show (admin or user) based on URL
    useEffect(() => {
        if (location.pathname.includes('admin')) {
            setSidebarItems(adminSideBarItems);
        } else {
            setSidebarItems(userSideBarItems);
        }
        return () => {
            setSidebarItems([]);
        };
    }, [location.pathname]);

    // Auto-select active menu item based on the current route
    const selectActiveItem = () => {
        const activeItemObj = sideBarItems.find((item) => location.pathname === item.linkTo);
        if (activeItemObj) {
            dispatch(changeActiveItem(activeItemObj.id));
        }
    };

    useEffect(() => {
        selectActiveItem();
    }, [location.pathname, sideBarItems]);

    return (
        <>
            <div
                className={`parker-wrapper ${transitionType === 'hide/show' && !hiddenForm ? 'sidebar-open' : ''} ${location?.pathname?.startsWith('/parker/session-view-details') ? 'custom-top-wrapper' : ''} `}
            >
                {transitionType === 'collapse/expand' && (
                    <div className={`parker-sidebar ${collapsedForm ? 'collapse-parker-sidebar' : 'expand-sidebar'}`}>
                        <div className="sidebar-main">
                            {/* Add first and last name at the top */}
                            <div className="sidebar-user-info">
                                <div className="user-full-name">
                                    <span>{userInfo?.name}</span>
                                </div>
                            </div>

                            {/* Add Account Settings at the top */}
                            <SidebarItem
                                key="account-settings"
                                item={{
                                    id: 'account-settings',
                                    name: 'Account Settings',
                                    linkTo: '/parker/account-settings'
                                }}
                                selectedItemId={activeItem}
                                handleSideBarClick={handleSideBarClick}
                            />

                            {/* Add space before other sidebar items */}
                            <div className="sidebar-space" />

                            {sideBarItems.map((item) => (
                                <SidebarItem
                                    customClass="parker-sidebar-items"
                                    key={item.id}
                                    item={item}
                                    selectedItemId={activeItem}
                                    handleSideBarClick={handleSideBarClick}
                                />
                            ))}
                        </div>
                        {/* Commenting out for future reference */}
                        {location.pathname.includes('parker') && (
                            <div className="sidebar-bottom">
                                <div className="sidebar-profile" onClick={() => handleSideBarClick({ name: 'Logout' })}>
                                    <div className="profile-icon">
                                        {userInfo?.userIcon ? (
                                            <img src={userInfo.userIcon} alt="User Icon" />
                                        ) : (
                                            <img src={userCircle} alt="user-circle" />
                                        )}
                                        {!collapsedForm && <span className="profile-name">{userInfo?.name}</span>}
                                    </div>
                                    {!collapsedForm && (
                                        <div className="profile-info">
                                            <img src={redLogout} alt="Logout" />
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                        <LogoutModal isOpen={showLogoutModal} handleModal={handleLogoutModal} />
                    </div>
                )}
                {transitionType === 'hide/show' && (
                    <div
                        className={`parker-sidebar ${hiddenForm ? 'hide-sidebar' : 'parker-show-sidebar'} ${location?.pathname?.startsWith('/parker/session-view-details') ? 'custom-top' : ''} `}
                    >
                        <div className="sidebar-main">
                            {/* Add first and last name at the top */}
                            <div className="sidebar-user-info">
                                <div className="user-full-name">
                                    <span>
                                        <span>{userInfo?.name}</span>
                                    </span>
                                </div>
                            </div>

                            {/* Add Account Settings at the top */}
                            <SidebarItem
                                key="account-settings"
                                item={{
                                    id: 'account-settings',
                                    name: 'Account Settings',
                                    linkTo: '/parker/account-settings'
                                }}
                                selectedItemId={activeItem}
                                handleSideBarClick={handleSideBarClick}
                            />

                            {/* Add space before other sidebar items */}
                            <div className="sidebar-space" />

                            {sideBarItems.map((item) => (
                                <SidebarItem
                                    customClass="parker-sidebar-items"
                                    key={item.id}
                                    item={item}
                                    selectedItemId={activeItem}
                                    handleSideBarClick={handleSideBarClick}
                                />
                            ))}
                        </div>
                        {/* Commenting out for future reference */}

                        <button
                            onClick={() => dispatch(logoutUser())}
                            hidden
                            type="button"
                            id="whole-app-logout-parker"
                        >
                            log out button
                        </button>
                        {location.pathname.includes('parker') && (
                            <div className="sidebar-bottom">
                                <div className="sidebar-profile" onClick={() => handleSideBarClick({ name: 'Logout' })}>
                                    <div className="profile-icon">
                                        {userInfo?.userIcon ? (
                                            <img src={userInfo.userIcon} alt="User Icon" />
                                        ) : (
                                            <img src={userCircle} alt="user-circle" />
                                        )}
                                        {!collapsedForm && <span className="profile-name">{userInfo?.name}</span>}
                                    </div>
                                    {!collapsedForm && (
                                        <div className="profile-info">
                                            <img src={redLogout} alt="Logout" />
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                        <LogoutModal isOpen={showLogoutModal} handleModal={handleLogoutModal} />
                    </div>
                )}
            </div>
        </>
    );
};

export default ParkerSidebar;
