import { useEffect, useState } from 'react';
import './PreviousSubscriptions.scss';
import { Col, Row } from 'react-bootstrap';
import axiosWrapper from '../../../utils/api';
import { API_URL } from '../../../utils/apiUrl';
import { useSelector } from 'react-redux';
import 'mapbox-gl/dist/mapbox-gl.css';
import SubcriptionDetailModal from '../../Modals/SubcriptionDetailModal/SubcriptionDetailModal';
import TruncatedText from '../../TextTruncate/TextTruncate';

const PreviousSubscriptions = () => {
    const [subscriptions, setSubscriptions] = useState([]);
    const [show, setShow] = useState(false);
    const [selectedLocationId, setSelectedLocationId] = useState(null);
    const { userToken } = useSelector((state) => state.auth);
    const subscriptionChange = useSelector((state) => state.root.subscriptionChange);

    useEffect(() => {
        fetchPreviousSubscriptions();
    }, [subscriptionChange]);

    const fetchPreviousSubscriptions = async () => {
        try {
            const url = `${API_URL.GET_PREVIOUS_SUBSCRIPTIONS}`;

            const response = await axiosWrapper('GET', url, {}, userToken);

            if (response?.data) {
                setSubscriptions(response.data); // Update state with fetched data
            } else {
                setSubscriptions([]); // Handle empty response
            }
        } catch (error) {
            setSubscriptions([]); // Handle error case
        }
    };

    const handleClose = () => setShow(false);
    const handleSubscribe = (item) => {
        setShow(true);
        setSelectedLocationId(item._id); // Use the unique identifier of the location
    };

    return (
        <div className="previous-content">
            <div className="session-title">
                <h4>Previous Subscriptions</h4>
            </div>

            <Row className="mt-4">
                {subscriptions?.length === 0 ? (
                    <p className="text-center">No previous subscriptions found.</p>
                ) : (
                    subscriptions.map((item) => (
                        <Col key={item._id} xs={12} sm={12} md={6} lg={4} className="mb-4">
                            <div className="active-data">
                                <div className="map-container">
                                    <img src={item?.location?.image} alt="location" />
                                </div>
                                <Row className="mt-3">
                                    <Col>
                                        <h4 className="mb-1">{item.location?.zone}</h4>
                                        <TruncatedText
                                            text={
                                                item?.name ||
                                                `${item.location?.address}, ${item.location?.addressType}, ${item.location?.city}, ${item.location?.state}`
                                            }
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="mt-2 mt-sm-0">
                                        <button
                                            type="button"
                                            className="manage-btn"
                                            onClick={() => handleSubscribe(item.location)}
                                        >
                                            Re-Subscribe
                                        </button>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    ))
                )}
                {show && (
                    <SubcriptionDetailModal
                        selectedLocationId={selectedLocationId}
                        show={show}
                        handleClose={handleClose}
                    />
                )}
            </Row>
        </div>
    );
};

export default PreviousSubscriptions;
