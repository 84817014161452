import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import MainRoutes from './routes/Routes';
import Loading from '@components/Loading/Loading';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorBoundaryComponent } from './components/ErrorBoundary';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-phone-input-2/lib/style.css';

function App() {
    const headerColor = '#00AB36';

    useEffect(() => {
        // Find or create the theme-color meta tag
        let metaThemeColor = document.querySelector('meta[name=theme-color]');
        if (!metaThemeColor) {
            metaThemeColor = document.createElement('meta');
            metaThemeColor.setAttribute('name', 'theme-color');
            document.head.appendChild(metaThemeColor);
        }
        metaThemeColor.setAttribute('content', headerColor);
    }, [headerColor]);
    return (
        <ErrorBoundary fallbackRender={({ error }) => <ErrorBoundaryComponent error={error} />}>
            <React.Suspense fallback={<Loading centered size={'lg'} />}>
                <React.Fragment>
                    <MainRoutes />
                    <ToastContainer autoClose={3000} />
                </React.Fragment>
            </React.Suspense>
        </ErrorBoundary>
    );
}

export default App;
