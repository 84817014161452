import './Sessions.scss';
import SessionCards from '../../SessionCards/SessionCards';
import Pagination from '../../Pagination/Pagination';
import Loading from '../../Loading/Loading';

const Sessions = ({ sessionData, page, setPage, totalCount, loading }) => {
    const itemsPerPage = 9; // Number of cards per page
    // Calculate total pages
    const totalPages = Math.ceil(totalCount / itemsPerPage);

    const handlePrevClick = () => {
        if (page > 0) setPage(page - 1);
    };

    const handleNextClick = () => {
        if (page < totalPages - 1) setPage(page + 1);
    };

    return (
        <div className="sessions">
            <div className="session-title">
                <h4>Sessions</h4>
            </div>
            {loading ? (
                <Loading centered fulWidth />
            ) : (
                <>
                    <div className="mt-3">
                        <SessionCards sessionData={sessionData} />
                    </div>
                    {totalPages > 1 && (
                        <Pagination
                            currentPage={page}
                            totalPages={totalPages}
                            onPrev={handlePrevClick}
                            onNext={handleNextClick}
                        />
                    )}
                </>
            )}
        </div>
    );
};

export default Sessions;
