export const dateConverter = (isoTime) => {
    const date = new Date(isoTime);

    const options = {
        timeZone: 'America/Chicago', // CST/CDT time zone
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false // Use 12-hour time format
    };

    // Format the date to CST/CDT using the Intl.DateTimeFormat API
    const formatter = new Intl.DateTimeFormat('en-US', options);
    const [
        { value: month },
        ,
        { value: day },
        ,
        { value: year },
        ,
        { value: hour },
        ,
        { value: minute },
        ,
        { value: second }
    ] = formatter.formatToParts(date);

    return { date: `${month}/${day}/${year}`, time: `${hour}:${minute}:${second}` };
};

export const isValidTimeRange = (hourlyRates, currentIndex, startTime, endTime) => {
    for (let i = 0; i < hourlyRates.length; i++) {
        if (i === currentIndex) continue; // Skip the current index
        const range = hourlyRates[i].availableHours;
        const daysMatch = hourlyRates[currentIndex].availableDays.some((day) =>
            hourlyRates[i].availableDays.includes(day)
        );
        if (
            daysMatch &&
            ((startTime > range.startTime && startTime < range.endTime) ||
                (endTime > range.startTime && endTime < range.endTime) ||
                (startTime <= range.startTime && endTime >= range.endTime))
        ) {
            return false;
        }
    }
    return true;
};

export const isValidDurationRange = (durations, currentIndex, startTime, endTime) => {
    for (let i = 0; i < durations.length; i++) {
        if (i === currentIndex) continue; // Skip the current index
        const range = durations[i];
        if (
            (startTime > range.durationStartTime && startTime < range.durationEndTime) ||
            (endTime > range.durationStartTime && endTime < range.durationEndTime) ||
            (startTime <= range.durationStartTime && endTime >= range.durationEndTime)
        ) {
            return false;
        }
    }
    return true;
};

export const isTimeWithinRange = (time, startTime, endTime) => {
    return time >= startTime && time <= endTime;
};

export const isObjectEmpty = (obj) => {
    for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            return true;
        }
    }
    return false;
};

export function customToFixed(num, decimals) {
    const factor = Math.pow(10, decimals);
    // Add a small value (epsilon) before rounding to mitigate floating-point issues
    const epsilon = 1e-10;
    const roundedNumber = Math.round((num + epsilon) * factor) / factor;
    return roundedNumber.toFixed(decimals);
}
const dateOptions = {
    month: 'numeric', // M format
    day: 'numeric', // D format
    year: '2-digit', // YY format
    timeZone: 'America/Chicago' // CST/CDT time zone
};
const timeOptions = {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false, // Use 24-hour format
    timeZone: 'America/Chicago'
};
export const dateFormat = (createdAt) => {
    const createdAtTime = new Date(createdAt);

    // Extract date components

    const formattedDate = createdAtTime.toLocaleDateString(undefined, dateOptions);

    // Combine them in the desired format
    return `${formattedDate}`;
};
export const timeFormat = (createdAt) => {
    const createdAtTime = new Date(createdAt);
    // Extract time components

    const formattedTime = createdAtTime.toLocaleTimeString(undefined, timeOptions);

    return `${formattedTime}`;
};
export const stopAtTime = (days, hours, minutes, createdAt) => {
    const createdAtTime = new Date(createdAt);

    if (typeof days !== 'number' || typeof hours !== 'number' || typeof minutes !== 'number') {
        throw new Error('days, hours, and minutes must be numbers');
    }

    if (days < 0 || hours < 0 || minutes < 0) {
        throw new Error('days, hours, and minutes must be non-negative');
    }

    const totalDuration = new Date(
        createdAtTime.getTime() + days * 24 * 60 * 60 * 1000 + hours * 60 * 60 * 1000 + minutes * 60 * 1000
    );

    const exactTime = totalDuration.toLocaleTimeString('en-US', timeOptions);

    return exactTime;
};
export const stopAtDate = (days, hours, minutes, createdAt) => {
    const createdAtTime = new Date(createdAt);

    if (typeof days !== 'number' || typeof hours !== 'number' || typeof minutes !== 'number') {
        return new Error('days, hours, and minutes must be numbers');
    }

    if (days < 0 || hours < 0 || minutes < 0) {
        return new Error('days, hours, and minutes must be non-negative');
    }
    const totalDuration = new Date(
        createdAtTime.getTime() + days * 24 * 60 * 60 * 1000 + hours * 60 * 60 * 1000 + minutes * 60 * 1000
    );

    const formattedDate = totalDuration.toLocaleDateString(undefined, dateOptions);
    return formattedDate;
};
export function convertToMinutes(days = 0, hours = 0, minutes = 0) {
    // Convert days to minutes
    const daysInMinutes = days * 24 * 60;
    // Convert hours to minutes
    const hoursInMinutes = hours * 60;
    // Total minutes
    const totalMinutes = daysInMinutes + hoursInMinutes + minutes;

    return totalMinutes;
}

export function formatDate(value, locale = 'en-US', options = { year: 'numeric', month: '2-digit', day: '2-digit' }) {
    if (!value) return '';

    let date;

    // If the value is already a Date object, use it directly
    if (value instanceof Date) {
        date = value;
    } else {
        // Attempt to parse the value as a date string or timestamp
        date = new Date(value);
    }

    // Check if the date is valid
    if (isNaN(date.getTime())) {
        console.warn(`Invalid date value: ${value}`);
        return '';
    }

    return date.toLocaleDateString(locale, options);
}

export function returnSum(list) {
    const sum = list?.reduce((total, item) => {
        return total + item.approvedMoney.amount / 100;
    }, 0);
    return sum;
}

// Converts full day names to shorthand, e.g., 'Monday' -> 'Mon'
export const formatDays = (days) => {
    if (!Array.isArray(days)) return 'N/A';

    return days
        .map((day) => day.charAt(0).toUpperCase() + day.slice(1, 3).toLowerCase()) // Capitalize and shorten to 3 chars
        .join(', '); // Join with comma and space
};

// Converts 24-hour time to 12-hour time with am/pm
export const formatTimeRange = (startTime, endTime) => {
    const formatTime = (time) => {
        const [hours, minutes] = time.split(':');
        const hours12 = +hours % 12 || 12; // Convert to 12-hour format
        const period = +hours < 12 ? 'am' : 'pm';
        return `${hours12}:${minutes} ${period}`;
    };
    return `${formatTime(startTime)} - ${formatTime(endTime)}`;
};

export const formatPhoneNumber = (phoneNumber) => {
    if (phoneNumber?.startsWith('+1')) {
        const digits = phoneNumber.replace(/\D/g, '').slice(1); // Remove non-digit characters and skip '+1'
        if (digits.length === 10) {
            return `+1 (${digits.slice(0, 3)}) ${digits.slice(3, 6)}-${digits.slice(6, 10)}`;
        }
    }
    return phoneNumber; // Return original format for other cases
};

export const capitalizeFirstLetter = (string) => {
    if (typeof string !== 'string' || string.length === 0) {
        return '';
    }
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};
