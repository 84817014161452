import Slider from 'react-slick';
import './ActiveSubcriptions.scss';
import { Button, Col, Modal, Row, Spinner } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
// import { useNavigate } from 'react-router-dom';

import { useEffect, useState } from 'react';
import axiosWrapper from '../../utils/api';
import { API_URL } from '../../utils/apiUrl';
import Map, { Marker } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import redMarker from '@icons/redMarker.svg';
import { useDispatch, useSelector } from 'react-redux';
import * as types from '../../redux/actions/actionTypes';
import TruncatedText from '../TextTruncate/TextTruncate';
import { useNavigate } from 'react-router-dom';
// Custom arrow components
const CustomPrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{
                ...style,
                display: 'block',
                backgroundImage: "url('https://i.ibb.co/xMyLwVX/Vector.png')",
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                width: '20px',
                height: '20px',
                zIndex: 1
            }}
            onClick={onClick}
        />
    );
};

const CustomNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{
                ...style,
                display: 'block',
                backgroundImage: "url('https://i.ibb.co/hfH2qrt/right-icon.png')",
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                width: '20px',
                height: '20px',
                zIndex: 1
            }}
            onClick={onClick}
        />
    );
};
const ActiveSubcriptions = ({ triggerAvailableSubscriptions, setTriggerAvailableSubscriptions }) => {
    const navigate = useNavigate();
    const isMobile = useMediaQuery({ query: '(max-width: 576px)' });
    const [subscriptions, setSubscriptions] = useState([]);
    const { userToken } = useSelector((state) => state.auth);
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [selectedSubscription, setSelectedSubscription] = useState(null);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const MAPBOX_TOKEN = import.meta.env.VITE_MAPBOX_ACCESS_TOKEN;

    useEffect(() => {
        fetchActiveSubscriptions();
    }, []);

    const fetchActiveSubscriptions = async () => {
        try {
            // Construct API URL
            const url = `${API_URL.GET_ACTIVE_SUBSCRIPTIONS}`;

            // Fetch data from API
            const response = await axiosWrapper('GET', url, {}, userToken);
            if (response?.data) {
                setSubscriptions(response.data); // Update state with fetched data
            } else {
                setSubscriptions([]); // Handle empty response
            }
        } catch (error) {
            setSubscriptions([]); // Handle error case
        }
    };

    // const handleCancelSubscription = async (subscriptionId) => {
    //     try {
    //         await axiosWrapper('POST', API_URL.CANCEL_SUBSCRIPTION(subscriptionId), {}, userToken);
    //         fetchActiveSubscriptions();
    //     } catch (error) {}
    // };

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: isMobile ? 1 : 2,
        slidesToScroll: isMobile ? 1 : 2,
        prevArrow: <CustomPrevArrow />,
        nextArrow: <CustomNextArrow />,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const handleCancelClick = (subscription) => {
        setSelectedSubscription(subscription);
        setShowCancelModal(true);
    };

    const handleConfirmCancel = async () => {
        setLoading(true);
        if (selectedSubscription) {
            try {
                await axiosWrapper('POST', API_URL.CANCEL_SUBSCRIPTION(selectedSubscription._id), {}, userToken);
                fetchActiveSubscriptions();
                dispatch({ type: types.ALL_RECORDS, data: { keyOfData: 'subscriptionChange', data: true } });
                setTriggerAvailableSubscriptions(!triggerAvailableSubscriptions);
                setShowCancelModal(false);
                setLoading(false);
            } catch (error) {
                setShowCancelModal(false);
                dispatch({ type: types.ALL_RECORDS, data: { keyOfData: 'subscriptionChange', data: false } });
                setLoading(false);
                setSelectedSubscription(null);
            }
        }
    };

    return (
        <div className="slide-content">
            {subscriptions.length === 0 ? (
                <p className="text-center">No active subscriptions found.</p>
            ) : isMobile ? (
                subscriptions.map((item) => (
                    <div key={item._id} className="slide-item">
                        <Row>
                            <Col xs={12} sm={12} className="mb-3">
                                <div className="active-data">
                                    <div className="map-container-subscription">
                                        <Map
                                            initialViewState={{
                                                longitude: item?.location?.longitude,
                                                latitude: item?.location?.latitude,
                                                zoom: 14
                                            }}
                                            style={{ width: '100%', height: '200px' }}
                                            mapStyle="mapbox://styles/mapbox/streets-v11"
                                            mapboxAccessToken={MAPBOX_TOKEN}
                                        >
                                            <Marker
                                                longitude={item?.location?.longitude || 44}
                                                latitude={item?.location?.latitude || 45}
                                                anchor="center"
                                            >
                                                <img src={redMarker} alt="Marker" className="custom-marker" />
                                            </Marker>
                                        </Map>
                                    </div>
                                    <Row className="mt-2 address-bar d-flex flex-column flex-lg-row">
                                        {/* First Row: Market and Status */}
                                        <Col lg={12} className="d-flex justify-content-between align-items-center mb-2">
                                            <h4 className="mb-0 text-truncate">{item?.location?.zone}</h4>
                                            <div className="status-section d-flex align-items-center">
                                                <div className="status-green"></div>
                                                <p className="mb-0">{item.status}</p>
                                            </div>
                                        </Col>

                                        {/* Second Row: Address */}
                                        <Col lg={12} className="mb-2">
                                            <p className="mb-0  text-truncate">
                                                {item?.name ||
                                                    `${item.location?.address}, ${item.location?.addressType}, ${item.location?.city}, ${item.location?.state}`}
                                            </p>
                                        </Col>

                                        {/* Third Row: Amount */}
                                        <Col lg={12}>
                                            <p className=" amount">
                                                ${item?.amount / 100} Per{' '}
                                                {item?.frequency === 'Weekly' ? 'Week' : 'Month'}
                                            </p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={12}>
                                            <button
                                                className="manage-btn my-3"
                                                type="button"
                                                onClick={() => {
                                                    navigate(
                                                        `/parker/manage-update-subscription?locationId=${item.location?._id}&selectedRateId=${item.location?.recurringRates.find((r) => r?.subscription === item?._id)?._id}&subscriptionId=${item?._id}`
                                                    );
                                                }}
                                            >
                                                Manage
                                            </button>
                                        </Col>
                                        <Col lg={12}>
                                            <button
                                                type="button"
                                                className="cancel-btn"
                                                onClick={() => handleCancelClick(item)}
                                            >
                                                Cancel Subscription
                                            </button>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </div>
                ))
            ) : (
                <Slider {...settings}>
                    {subscriptions.map((item) => (
                        <div key={item._id} className="slide-item">
                            <Row>
                                <Col xs={12} sm={12}>
                                    <div className="active-data">
                                        <div className="map-container-subscription">
                                            <Map
                                                initialViewState={{
                                                    longitude: item?.location?.longitude,
                                                    latitude: item?.location?.latitude,
                                                    zoom: 14
                                                }}
                                                style={{ width: '100%', height: '200px' }}
                                                mapStyle="mapbox://styles/mapbox/streets-v11"
                                                mapboxAccessToken={MAPBOX_TOKEN}
                                            >
                                                <Marker
                                                    longitude={item?.location?.longitude || 44}
                                                    latitude={item?.location?.latitude || 45}
                                                    anchor="center"
                                                >
                                                    <img src={redMarker} alt="Marker" className="custom-marker" />
                                                </Marker>
                                            </Map>
                                        </div>

                                        <Row className="mt-3 address-bar d-flex flex-column flex-lg-row">
                                            {/* First Row: Market and Status */}
                                            <Col
                                                lg={12}
                                                className="d-flex justify-content-between align-items-center mb-2"
                                            >
                                                <h4 className="mb-0 text-truncate">{item?.location?.zone}</h4>
                                                <div className="status-section d-flex align-items-center">
                                                    <div className="status-green"></div>
                                                    <p className="mb-0">{item.status}</p>
                                                </div>
                                            </Col>

                                            {/* Second Row: Address */}
                                            <Col lg={12} className="mb-2">
                                                <TruncatedText
                                                    text={
                                                        item?.name ||
                                                        `${item.location?.address}, ${item.location?.addressType}, ${item.location?.city}, ${item.location?.state}`
                                                    }
                                                />
                                            </Col>

                                            {/* Third Row: Amount */}
                                            <Col lg={12}>
                                                <p className="mb-2 amount">
                                                    ${item?.amount / 100} Per{' '}
                                                    {item?.frequency === 'Weekly' ? 'Week' : 'Month'}
                                                </p>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col lg={12}>
                                                <button
                                                    className="manage-btn mb-3"
                                                    type="button"
                                                    onClick={() => {
                                                        navigate(
                                                            `/parker/manage-update-subscription?locationId=${item.location?._id}&selectedRateId=${item.location?.recurringRates.find((r) => r?.subscription === item?._id)?._id}&subscriptionId=${item?._id}`
                                                        );
                                                    }}
                                                >
                                                    Manage
                                                </button>
                                            </Col>
                                            <Col lg={12}>
                                                <button
                                                    type="button"
                                                    className="cancel-btn text-truncate"
                                                    onClick={() => handleCancelClick(item)}
                                                >
                                                    Cancel Subscription
                                                </button>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    ))}
                </Slider>
            )}
            <Modal show={showCancelModal} onHide={() => setShowCancelModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Cancel Subscription</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to cancel the subscription for zone "{selectedSubscription?.location?.zone}"?
                </Modal.Body>
                <Modal.Footer>
                    <Button disabled={loading} variant="secondary" onClick={() => setShowCancelModal(false)}>
                        No, Keep
                    </Button>
                    <Button disabled={loading} variant="danger" onClick={handleConfirmCancel}>
                        {loading ? <Spinner animation="border" size="sm" /> : 'Yes, Cancel'}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default ActiveSubcriptions;
