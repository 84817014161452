import { useState, useEffect, useMemo } from 'react';
import './NearbyLocation.scss';
import searchNormal from '@icons/search-icon.svg';
import { Col, Row } from 'react-bootstrap';
import DropdownFilter from '../Dashboard/DropDown/Filter/DropdownFilter';
import SubcriptionDetailModal from '../Modals/SubcriptionDetailModal/SubcriptionDetailModal';
import 'mapbox-gl/dist/mapbox-gl.css';
import { Link } from 'react-router-dom';
import { debounce } from 'lodash';
import TruncatedText from '../TextTruncate/TextTruncate';
import { API_URL } from '../../utils/apiUrl';
import { toast } from 'react-toastify';
import axiosWrapper from '../../utils/api';
import { useSelector } from 'react-redux';

const NearbyLocation = () => {
    const { userToken } = useSelector((state) => state?.auth);
    const [show, setShow] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchInput, setSearchInput] = useState('');
    const [selectedLocationId, setSelectedLocationId] = useState(null);
    const [selectedCity, setSelectedCity] = useState('');
    const [cityOptions, setCityOptions] = useState([
        { value: '', label: 'All Cities' },
        { value: 'New York', label: 'New York' },
        { value: 'Los Angeles', label: 'Los Angeles' }
    ]);
    const [locations, setLocations] = useState([]);

    const handleClose = () => setShow(false);
    const handleSubscribe = (item) => {
        setShow(true);
        setSelectedLocationId(item._id); // Use the unique identifier of the location
    };

    useEffect(() => {
        if (locations && locations.length > 0) {
            const allCities = [...new Set(locations.map((option) => option.city))].map((city) => ({
                value: city,
                label: city
            }));
            setCityOptions([{ value: '', label: 'All Cities' }, ...allCities]);
        }
    }, [locations]);

    // Debounced search handler
    const handleSearch = useMemo(
        () =>
            debounce((query) => {
                setSearchQuery(query);
            }, 300),
        []
    );

    const onSearchChange = (e) => {
        const query = e.target.value;
        setSearchInput(query);
        handleSearch(query);
    };

    const fetchLocations = async () => {
        const allCities =
            selectedCity && selectedCity?.length > 0 ? selectedCity?.map((city) => city.value)?.join(',') : '';
        const url = `${API_URL.GET_FILTERED_LOCATIONS}?page=${1}&search=${encodeURIComponent(
            searchQuery
        )}${allCities && allCities.length > 0 ? `&city=${encodeURIComponent(allCities)}` : ''}`;

        try {
            const response = await axiosWrapper('GET', url, {}, userToken);
            setLocations(response.data);
        } catch (error) {
            toast.error(error?.response?.data?.message || error?.message);
        }
    };

    // Refetch whenever search, city, or page changes
    useEffect(() => {
        fetchLocations();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchQuery, selectedCity]);

    return (
        <div className="nearby-location">
            <Row className="align-items-center">
                <Col xs={12} sm={12} md={10} lg={12} xl={8}>
                    <h4>Nearby Locations</h4>
                </Col>
                {/* {!isMobile && ( */}
                <Col xs={12} sm={12} md={12} lg={12} xl={4} className="d-flex justify-content-end mt-3 mt-xl-0">
                    <div className="right-items">
                        <div className="search-input-container">
                            <input
                                type="text"
                                name="search"
                                placeholder="Search"
                                value={searchInput}
                                onChange={onSearchChange}
                            />
                            <img src={searchNormal} alt="Search icon" className="search-icon" />
                        </div>
                        <div className="dropdown-container">
                            <DropdownFilter
                                options={cityOptions}
                                selectedOptions={selectedCity}
                                onChange={(option) => setSelectedCity(option)}
                                title="Filter by City"
                            />
                        </div>
                    </div>
                </Col>
            </Row>
            <Row className="for-margin">
                {locations.length > 0 ? (
                    locations.map((item) => (
                        <Col xs={12} sm={12} md={6} lg={6} xl={4} className="mb-3" key={item.id}>
                            <div className="active-data">
                                <div className="map-container">
                                    <img
                                        src={item?.image}
                                        alt="location"
                                        style={{
                                            objectFit: 'contain', // Adjust to 'contain', 'fill', etc., based on your needs
                                            width: '100%',
                                            height: '100%'
                                        }}
                                    />
                                </div>

                                <Row className="mt-3">
                                    <Col>
                                        <h6 className="mb-1 location-zone">Zone {item?.zone || ''}</h6>
                                        <h4>{item.name}</h4>
                                        <TruncatedText
                                            text={`${item.address}, ${item.addressType}, ${item.city}, ${item.state}`}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mt-3 me-0">
                                    <div className="button-wrapper">
                                        <Col
                                            xs={{
                                                span: 12
                                                // order: 1
                                            }}
                                            sm={{
                                                span: 6
                                            }}
                                        >
                                            <button
                                                type="button"
                                                className="manage-btn"
                                                onClick={() => handleSubscribe(item)}
                                            >
                                                Subscribe
                                            </button>
                                        </Col>
                                        <Col
                                            xs={{
                                                span: 12,
                                                order: 'first'
                                            }}
                                            sm={{
                                                span: 6,
                                                order: 'last'
                                            }}
                                        >
                                            <Link to={`/zone/${item.zone}`} className="cancel-btn">
                                                Park Now
                                            </Link>
                                        </Col>
                                    </div>
                                </Row>
                            </div>
                        </Col>
                    ))
                ) : (
                    <Col>
                        <p>No nearby locations found.</p>
                    </Col>
                )}
            </Row>
            {show && (
                <SubcriptionDetailModal selectedLocationId={selectedLocationId} show={show} handleClose={handleClose} />
            )}
        </div>
    );
};

export default NearbyLocation;
