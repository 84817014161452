import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

const SidebarItem = ({ item, selectedItemId, handleSideBarClick, customClass = '' }) => {
    const { collapsedForm } = useSelector((state) => state.sidebar);

    return (
        <Link
            to={item.linkTo}
            className={`sidebar-item ${customClass} ${item.id === selectedItemId ? 'active-sidebar-item' : 'inactive-sidebar-item'} ${
                item.name === 'Account Settings' ? 'account-settings-class' : ''
            }`}
            style={item.name === 'Account Settings' ? { padding: '16px' } : {}}
            onClick={() => handleSideBarClick(item)}
        >
            {item.iconLight && <img src={item.iconLight} className="sidebar-item-icon" alt="sidebar-icon" />}
            <span
                className={`sidebar-item-text ${collapsedForm ? 'hide-sidebar-item-text' : 'show-sidebar-item-text'}`}
            >
                {item.name}
            </span>
        </Link>
    );
};

export default SidebarItem;
