import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import './TruncatedText.scss';

const TruncatedText = ({ text }) => {
    // Media query to detect screen size
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

    const renderTooltip = (props) => <Tooltip {...props}>{text}</Tooltip>;

    return (
        <div>
            {!isMobile ? (
                <OverlayTrigger placement="top" overlay={renderTooltip}>
                    <p className="truncate-text location-address mb-0">{text}</p>
                </OverlayTrigger>
            ) : (
                <p className="truncate-text location-address mb-0">{text}</p>
            )}
        </div>
    );
};

export default TruncatedText;
