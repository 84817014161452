import { useEffect, useState } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import './ManageSubscription.scss';
import axiosWrapper from '../../../utils/api';
import { API_URL } from '../../../utils/apiUrl';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { PaymentForm, CreditCard } from 'react-square-web-payments-sdk';
import { toast } from 'react-toastify';
import Select from 'react-select';
import SelectPaymentMethod from '../../SelectPaymentMethod/SelectPaymentMethod';
import { useMediaQuery } from 'react-responsive';
import plus from '@icons/GreenPlus.svg';

const ManageSubscriptionUpdate = () => {
    const [selectedRate, setSelectedRate] = useState(null);
    const [searchParams] = useSearchParams();
    const location = useLocation();
    const navigate = useNavigate();
    const { userInfo, userToken } = useSelector((state) => state.auth);
    const isMobile = useMediaQuery({ query: '(max-width: 576px)' });

    const [locationDetails, setLocationDetails] = useState(null);
    // const { locationId, selectedRate } = location.state || '';
    const locationId = searchParams.get('locationId');
    const selectedRateId = searchParams.get('selectedRateId');
    const subscriptionId = searchParams.get('subscriptionId');
    const newVehicleId = searchParams.get('newVehicleId');
    //eslint-disable-next-line
    const [vehicles, setVehicles] = useState([]);
    const [selectedVehicles, setSelectedVehicles] = useState([]);
    const [vehicleOptions, setVehicleOptions] = useState([]);
    const [previousVehicleIds, setPreviousVehicleIds] = useState([]); // State for previous vehicle IDs

    // Uplifted state for cards
    const [cards, setCards] = useState([]);
    const [defaultCardId, setDefaultCardId] = useState(null);
    const [selectedCard, setSelectedCard] = useState(null);

    const [isAddingCard, setIsAddingCard] = useState(false);

    // For delete modal
    const [showModal, setShowModal] = useState(false);
    const [cardToDelete, setCardToDelete] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isPaymentProcessing, setIsPaymentProcessing] = useState(false);

    const [salesTax, setSalesTax] = useState(0);
    const [serviceFee, setServiceFee] = useState(0);
    const [isRenew, setIsRenew] = useState(true);
    const [renewDate, setRenewDate] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            if (subscriptionId) {
                await fetchSubscriptionDetails(subscriptionId); // Wait for subscription details
            }
            if (locationId) {
                await fetchLocationDetails(locationId); // Fetch location details
            }
            await fetchUserVehicles(); // Fetch vehicles after subscription details
            fetchCards();
            fetchDefaultCard();
        };

        fetchData();
    }, [subscriptionId, locationId]);

    useEffect(() => {
        // Calculate Service Fee
        if (selectedRate?.rate) {
            const calculatedFee = parseFloat(((selectedRate?.rate + serviceFee) * 0.075).toFixed(2));
            setSalesTax(calculatedFee);
        }
        // Calculate Renew Dat
        if (selectedRate) {
            const date = calcRenewalDate(selectedRate);
            setRenewDate(date.toISOString().split('T')[0]);
        }
    }, [selectedRate]);

    // const subtotal = useMemo(() => {
    //     const rate = parseFloat(selectedRate?.rate) || 0;
    //     const fee = parseFloat(serviceFee) || 0;
    //     return rate + fee;
    // }, [selectedRate, serviceFee]);

    const fetchLocationDetails = async (locationId) => {
        const response = await axiosWrapper('GET', `${API_URL.GET_LOCATION_BY_ID(locationId)}`, {}, userToken);
        if (response?.data) {
            setLocationDetails(response.data);
            const publicRecurringRates = response.data?.recurringRates?.filter((rate) => rate.isPublic) || [];

            const currentRate = publicRecurringRates?.find((item) => item?._id === selectedRateId);
            if (currentRate) {
                setSelectedRate(currentRate);
            }
            // Calculate the service fee
            const fee = calcServiceCharges(response.data, currentRate);
            setServiceFee(fee);
        }
    };

    const fetchSubscriptionDetails = async (subscriptionId) => {
        try {
            setIsLoading(true);
            const response = await axiosWrapper(
                'GET',
                `${API_URL.GET_SUBSCRIPTION_BY_ID(subscriptionId)}`,
                {},
                userToken
            );
            if (response?.data) {
                const subscription = response.data;

                const vehicleIds = subscription.vehicles.map((vehicle) => vehicle._id);
                if (newVehicleId) {
                    vehicleIds.push(newVehicleId);
                }
                setSelectedVehicles(vehicleIds);
                setPreviousVehicleIds(vehicleIds);
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    };

    const fetchUserVehicles = async () => {
        const response = await axiosWrapper('GET', `${API_URL.GET_VEHICLES}`, {}, userToken);
        if (response?.data) {
            setVehicles(response.data?.vehicles);

            const options = response.data?.vehicles.map((vehicle) => ({
                value: vehicle?._id,
                label: `${vehicle.vehicleMake} | ${vehicle.licensePlate}`
            }));

            options.unshift({
                value: 'new',
                label: 'Add New Vehicle'
            });
            setVehicleOptions(options);
        }
    };

    const fetchCards = async () => {
        const response = await axiosWrapper('GET', `${API_URL.GET_CARDS}`, {}, userToken);
        setCards(response?.data || []);
    };

    const fetchDefaultCard = async () => {
        const response = await axiosWrapper('GET', `${API_URL.GET_DEFAULT_CARD}`, {}, userToken);
        if (response?.data) {
            setDefaultCardId(response.data.id);
            setSelectedCard(response.data.id);
        }
    };

    const handleVehicleSelection = (selectedOptions) => {
        const selectedValues = selectedOptions ? selectedOptions.map((option) => option.value) : [];
        if (selectedOptions?.length > 0 && selectedOptions[selectedOptions.length - 1]?.value === 'new') {
            navigate(
                `/parker/add-vehicle?locationId=${locationId}&selectedRateId=${selectedRateId}&previousPage=manage-subscription&subscriptionId=${subscriptionId}`
            );
            return;
        }
        if (selectedValues.length > 3) {
            toast.error('You can select up to 3 vehicles.');
            return;
        }
        setSelectedVehicles(selectedValues);
    };

    const isPaymentDisabled = selectedVehicles.length < 1 || !selectedCard;

    const onSetDefaultCard = async (cardId) => {
        const response = await axiosWrapper('POST', API_URL.SET_DEFAULT_CARD, { cardId }, userToken);
        setDefaultCardId(response?.data?.defaultCardId);
        setSelectedCard(response?.data?.defaultCardId);
        fetchCards();
    };

    const onDeleteCard = (cardId) => {
        setCardToDelete(cardId);
        setShowModal(true);
    };

    const confirmDelete = async () => {
        if (!cardToDelete) return;
        setIsLoading(true);
        try {
            await axiosWrapper('DELETE', `${API_URL.DELETE_CARD(cardToDelete)}`, {}, userToken);
            setCards((prevCards) => prevCards.filter((card) => card.id !== cardToDelete));

            if (cardToDelete === defaultCardId) {
                // If the deleted card was the default, handle accordingly
                if (cards.length > 0) {
                    const remainingCards = cards.filter((card) => card.id !== cardToDelete);
                    if (remainingCards.length > 0) {
                        await onSetDefaultCard(remainingCards[0].id);
                    } else {
                        setDefaultCardId(null);
                        setSelectedCard(null);
                    }
                } else {
                    setDefaultCardId(null);
                    setSelectedCard(null);
                }
            }

            setShowModal(false);
            setCardToDelete(null);
            setIsLoading(false);
        } catch (error) {
            setShowModal(false);
            setCardToDelete(null);
            setIsLoading(false);
        }
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setCardToDelete(null);
    };

    const handleCardTokenize = async (token) => {
        if (!selectedVehicles.length) {
            toast.error('Please select at least one vehicle before adding a card.');
            return;
        }

        try {
            // Add new card and associate with vehicles
            const newCard = await axiosWrapper(
                'POST',
                `${API_URL.ADD_CARD}`,
                {
                    sourceId: token.token,
                    vehicles: selectedVehicles,
                    cardDetails: token.details.card
                },
                userToken
            );

            if (cards?.length > 0) {
                await fetchCards();
            } else {
                await onSetDefaultCard(newCard?.cardId);
                await fetchCards();
            }

            setIsAddingCard(false);
            // You can set the newly added card as selectedCard if desired
        } catch (error) {
            setIsAddingCard(false);
        }
    };

    const handleConfirm = async () => {
        try {
            setIsPaymentProcessing(true);
            await axiosWrapper(
                'POST',
                `${API_URL.MANAGE_UPDATE_SUBSCRIPTION}`,
                {
                    phoneNumber: userInfo?.phoneNumber,
                    locationId: locationId,
                    selectedRateId: selectedRate?._id,
                    previousVehicleIds: previousVehicleIds,
                    vehicleIds: selectedVehicles
                },
                userToken
            );

            navigate('/parker/subscriptions');
            setIsPaymentProcessing(false);
        } catch (error) {
            setIsPaymentProcessing(false);
        }
    };

    const calcServiceCharges = (locationDetails, currentRate) => {
        const serviceCharges = locationDetails?.fees?.find((f) => f.feeChannel === 'subscriptions');

        if (serviceCharges?.feePercentage) {
            // Calculate percentage fee and format to 2 decimal places
            const calculatedFee = ((selectedRate?.rate || currentRate?.rate) * serviceCharges.feePercentage) / 100;
            return parseFloat(calculatedFee.toFixed(2));
        } else if (serviceCharges?.isFixedPrice) {
            // Format fixed price fee to 2 decimal places
            return parseFloat((serviceCharges?.feePrice || 0).toFixed(2));
        } else {
            // Return 0 with 2 decimal places
            return parseFloat((0).toFixed(2));
        }
    };

    const calcRenewalDate = (selectedRate) => {
        const frequency = selectedRate?.frequency.toLowerCase();
        let renewalDate = new Date();
        switch (frequency) {
            case 'daily':
                renewalDate.setDate(renewalDate.getDate() + 1);
                break;
            case 'weekly':
                renewalDate.setDate(renewalDate.getDate() + 7);
                break;
            case 'monthly':
                renewalDate.setMonth(renewalDate.getMonth() + 1);
                break;
            case 'quarterly': // New case for Quarterly
                renewalDate.setMonth(renewalDate.getMonth() + 3);
                break;
            case 'yearly':
            case 'annual':
            case 'annually':
                renewalDate.setFullYear(renewalDate.getFullYear() + 1);
                break;
            default:
                renewalDate = null;
        }

        return renewalDate;
    };

    const feeName = Array.isArray(locationDetails?.fees)
        ? locationDetails.fees.find((fee) => fee?.feeChannel === 'subscriptions')?.feeName || 'Service Fee'
        : 'Service Fee';

    return (
        <>
            <div className="payment-modal">
                <Row>
                    <Col xs={12} sm={12} md={12} lg={6}>
                        {isMobile && <p>Select Payment Method</p>}
                        <SelectPaymentMethod
                            isMobile={isMobile}
                            cards={cards}
                            selectedCard={selectedCard}
                            defaultCardId={defaultCardId}
                            onSelectCard={setSelectedCard}
                            onSetDefaultCard={onSetDefaultCard}
                            onDeleteCard={onDeleteCard}
                            showModal={showModal}
                            handleCloseModal={handleCloseModal}
                            confirmDelete={confirmDelete}
                            cardToDelete={cardToDelete}
                            isLoading={isLoading}
                        />
                        <div className="my-4">
                            {!isAddingCard ? (
                                <div className="add-card-btn" onClick={() => setIsAddingCard(true)}>
                                    <span>Add New Card</span>
                                    <img src={plus} alt="Plus sign" />
                                </div>
                            ) : (
                                <div className="card-form-container">
                                    <PaymentForm
                                        applicationId={import.meta.env.VITE_APPLICATION_ID}
                                        locationId={import.meta.env.VITE_LOCATION_ID}
                                        cardTokenizeResponseReceived={(token) => handleCardTokenize(token)}
                                        createPaymentRequest={() => ({
                                            countryCode: 'US',
                                            currencyCode: 'USD',
                                            total: {
                                                amount: '1.00', // Temporary amount for card validation
                                                label: 'Card Validation'
                                            }
                                        })}
                                    >
                                        <CreditCard
                                            buttonProps={{
                                                css: {
                                                    backgroundColor: selectedVehicles.length ? '#1FA846' : '#ccc',
                                                    cursor: selectedVehicles.length ? 'pointer' : 'not-allowed',
                                                    color: '#fff',
                                                    width: '100%',
                                                    fontSize: '14px',

                                                    '&:hover': {
                                                        backgroundColor: selectedVehicles.length ? '#1C9442' : '#ccc'
                                                    }
                                                }
                                            }}
                                        >
                                            Add Card
                                        </CreditCard>
                                    </PaymentForm>
                                    <button
                                        type="button"
                                        className="btn btn-secondary mt-3"
                                        onClick={() => setIsAddingCard(false)}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            )}
                            {!selectedCard && (
                                <p className="text-danger mt-2">Please add at least one card to your account.</p>
                            )}
                        </div>
                        <div className="vehicle-select mt-4">
                            <h6>Assign up to 3 vehicles</h6>
                            <p>Only one vehicle may park at a time</p>

                            <div className="select-vehicle mt-2">
                                <Select
                                    isMulti
                                    name="vehicles"
                                    options={vehicleOptions}
                                    defaultValue={vehicleOptions.find((option) => option.isDefault)} // Preselect default vehicle(s)
                                    className="single-select-field"
                                    classNamePrefix="__select-days"
                                    value={vehicleOptions.filter((option) => selectedVehicles.includes(option.value))}
                                    onChange={handleVehicleSelection}
                                />
                            </div>
                            {selectedVehicles.length < 1 && (
                                <p className="text-danger mt-2">
                                    Please select at least one vehicle and a payment method before proceeding.
                                </p>
                            )}
                        </div>
                    </Col>

                    <Col xs={12} sm={12} md={12} lg={6} className="mt-3 mt-lg-0">
                        <h5>Summary</h5>
                        <div className="total-amount mt-3">
                            <div>
                                <Row>
                                    <Col>
                                        <h4>Zone</h4>
                                    </Col>
                                    <Col className="end-content">
                                        <h4>{locationDetails?.zone || 'N/A'}</h4>
                                    </Col>
                                </Row>
                                <hr />

                                <Row>
                                    <Col lg={8} xs={8}>
                                        <h4>{locationDetails?.ownerName || 'N/A'}</h4>
                                        <p>
                                            {locationDetails?.address?.split(',')[0] || 'Address not available'},{' '}
                                            {/* Extracted street address */}
                                            {locationDetails?.city || 'N/A'}, {locationDetails?.state || 'N/A'}{' '}
                                            {locationDetails?.zipCode || ''}
                                        </p>
                                    </Col>
                                    <Col lg={4} xs={4} className="end-content">
                                        <h4>
                                            ${selectedRate?.rate || 'N/A'}/
                                            {selectedRate?.frequency?.toLowerCase() || 'N/A'}
                                        </h4>
                                    </Col>
                                </Row>
                                <hr />

                                <Row>
                                    <Col>
                                        <h4>Rate Name</h4>
                                        <p>{selectedRate?.rateName || 'N/A'}</p>
                                    </Col>
                                </Row>
                                <hr />

                                <Row>
                                    <Col lg={12} xs={12}>
                                        <h4>Valid Parking Days</h4>
                                        <p>
                                            {selectedRate?.validParkingDays
                                                ?.map((day) => day.charAt(0).toUpperCase() + day.slice(1)) // Capitalized days
                                                .join(', ') || 'N/A'}
                                        </p>
                                    </Col>
                                    <Col lg={12} xs={12} className="mt-3">
                                        <h4>Valid Parking Time</h4> {/* Aligned left */}
                                        <p>
                                            {selectedRate?.validParkingTime?.startTime || 'N/A'} -{' '}
                                            {selectedRate?.validParkingTime?.endTime || 'N/A'}
                                        </p>
                                    </Col>
                                </Row>
                                <hr />
                                {/* Commenting out for future use */}
                                {/* <Row>
                                    <Col lg={8} xs={9}>
                                        <h4>Auto Renewal</h4>
                                        <p>Renewal Date: {renewDate}</p>
                                    </Col>
                                    <Col lg={4} xs={3} className="end-content">
                                        <label className="renew-switch">
                                            <input
                                                type="checkbox"
                                                checked={isRenew}
                                                onChange={() => setIsRenew((prev) => !prev)}
                                            />
                                            <span className="renew-slider"></span>
                                        </label>
                                    </Col>
                                </Row>
                                <hr /> */}
                                <Row>
                                    <Col lg={8} xs={9}>
                                        <h4>Subscription Rate</h4>
                                    </Col>
                                    <Col lg={4} xs={3} className="end-content">
                                        <h4>${selectedRate?.rate || 'N/A'}</h4>
                                    </Col>
                                </Row>

                                <hr />
                                <Row>
                                    <Col lg={8} xs={9}>
                                        <h4>{feeName || 'Service Fee'}</h4>
                                    </Col>
                                    <Col lg={4} xs={3} className="end-content">
                                        <h4>${serviceFee}</h4>
                                    </Col>
                                </Row>
                                <hr />
                                <Row>
                                    <Col lg={8} xs={9}>
                                        <h4>Sales Tax (7.5%)</h4>
                                    </Col>
                                    <Col lg={4} xs={3} className="end-content">
                                        <h4>${salesTax.toFixed(2)}</h4>
                                    </Col>
                                </Row>
                                <hr />

                                <Row>
                                    <Col lg={8} xs={9}>
                                        <h4>Total</h4>
                                    </Col>
                                    <Col lg={4} xs={3} className="end-content">
                                        <h4>${(selectedRate?.rate + salesTax + serviceFee).toFixed(2)}</h4>
                                    </Col>
                                </Row>
                                <div className="mt-5">
                                    <div className="add-btn">
                                        <button
                                            onClick={handleConfirm}
                                            disabled={isPaymentDisabled || isPaymentProcessing}
                                            type="button"
                                        >
                                            {isPaymentProcessing ? <Spinner animation="border" size="sm" /> : 'Confirm'}
                                        </button>
                                        {/* <button
                                            onClick={() => {
                                                navigate('/parker/subscriptions');
                                            }}
                                            disabled={isPaymentDisabled || isPaymentProcessing}
                                            type="button"
                                            className="cancel-btn mt-3"
                                        >
                                            Cancel
                                        </button> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default ManageSubscriptionUpdate;
