/* eslint-disable no-unused-vars */
// src/components/Pagination/Pagination.jsx

import React from 'react';
import PropTypes from 'prop-types';
import './Pagination.scss';
import prevIcon from '@icons/prev-icon.svg';
import nextIcon from '@icons/next-icon.svg';

const Pagination = ({ currentPage, totalPages, onPrev, onNext }) => {
    return (
        <div className="pre-next-icon mt-3">
            <div
                className={`prev-icon ${currentPage === 0 ? 'disabled' : 'enabled'}`}
                onClick={currentPage > 0 ? onPrev : null}
            >
                <img
                    src={prevIcon}
                    alt="prevIcon"
                    style={{
                        color: currentPage === 0 ? '#AFACAC' : '#00AB36'
                    }}
                />
            </div>
            <div className="page-number">
                {currentPage + 1} of {totalPages}
            </div>
            <div
                className={`next-icon ${currentPage === totalPages - 1 ? 'disabled' : 'enabled'}`}
                onClick={currentPage < totalPages - 1 ? onNext : null}
            >
                <img
                    src={nextIcon}
                    alt="nextIcon"
                    style={{
                        color: currentPage === totalPages - 1 ? '#AFACAC' : '#00AB36'
                    }}
                />
            </div>
        </div>
    );
};

Pagination.propTypes = {
    currentPage: PropTypes.number.isRequired,
    totalPages: PropTypes.number.isRequired,
    onPrev: PropTypes.func.isRequired,
    onNext: PropTypes.func.isRequired
};

export default Pagination;
