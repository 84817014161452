import './SessionCards.scss';
import { Col, Row } from 'react-bootstrap';
import arrow from '@icons/arrow-right.svg';
import tick from '@icons/tick.svg';
import { useNavigate } from 'react-router-dom';

const SessionCards = ({ sessionData }) => {
    const allSessions = sessionData?.length ? sessionData : [];
    const navigate = useNavigate();
    return (
        <div className="session-cards mt-4">
            <Row>
                {allSessions?.map((item, index) => (
                    <Col key={index + item?.zone + '--session'} xl={4} lg={6} md={6} sm={12} className="mb-4">
                        <div className="session-content">
                            <Row>
                                <Col lg={6} xs={6} sm={6} className="mb-2">
                                    <h4>Zone {item?.zone}</h4>
                                    {item?.location?.address && (
                                        <p className="location-address">
                                            {item?.location?.address?.split(',')[0] || 'Address not available'},{' '}
                                            {/* Extracted street address */}
                                            {item?.location?.city || ''}, {item?.location?.state || ''}{' '}
                                            {item?.location?.zipCode || ''}
                                        </p>
                                    )}
                                </Col>
                                <Col lg={6} xs={6} sm={6} className="placement mb-2">
                                    <h4>${item?.totalMoney}</h4>
                                </Col>
                                <Col lg={6} xs={6} sm={6} className="mb-2">
                                    <p>Plate No</p>
                                </Col>
                                <Col lg={6} xs={6} sm={6} className="placement mb-2">
                                    <span>{item?.plateNo}</span>
                                </Col>
                                <Col lg={6} xs={6} sm={6} className="mb-2">
                                    <p>Date</p>
                                </Col>
                                <Col lg={6} xs={6} sm={6} className="placement mb-2">
                                    <span>{item?.date}</span>
                                </Col>
                                <Col lg={6} xs={6} sm={6} className="mb-2">
                                    <p>Total Time</p>
                                </Col>
                                <Col lg={6} xs={6} sm={6} className="placement mb-2">
                                    <span>{item?.totalTime}</span>
                                </Col>
                            </Row>
                            <hr />
                            <div className="view-btn">
                                <button
                                    type="button"
                                    onClick={() => navigate(`/parker/session-view-details?sessionId=${item?.id}`)}
                                >
                                    View Details
                                    <img src={arrow} alt="arrow" />
                                </button>
                            </div>
                            {item?.status === true && (
                                <div className="tick">
                                    <img src={tick} alt="tick" />
                                </div>
                            )}
                        </div>
                    </Col>
                ))}

                {!sessionData?.length && <p className="text-center mt-5">Sessions data not found.</p>}
            </Row>
        </div>
    );
};

export default SessionCards;
