import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logoutUser } from '../../redux/auth/auth_slice';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './LogoutModal.scss';

const LogoutModal = ({ isOpen, handleModal }) => {
    const { role = '' } = useSelector((state) => state.auth.userInfo) || { role: '' };

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const logoutHandler = () => {
        if (role && role === 'PARKER') {
            navigate('/parker/auth');
        } else {
            navigate('/admin/login');
        }
        dispatch(logoutUser());
    };
    return (
        <div className="logout-modal">
            <Modal centered show={isOpen} onHide={handleModal} keyboard={true}>
                <Modal.Header closeButton>
                    <Modal.Title>Logout</Modal.Title>
                </Modal.Header>
                <Modal.Body>Do you want to Logout?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleModal}>
                        Cancel
                    </Button>
                    <Button variant="danger" id="logout-btn" onClick={logoutHandler}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default LogoutModal;
