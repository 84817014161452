import { useDispatch, useSelector } from 'react-redux';
import { changeCollapsedForm, changeHiddenForm } from '@redux/sidebar/sidebar_slice';
import logo from '@icons/white-logo.svg';
import menuIcon from '@icons/menu-icon.svg';
import { useLocation } from 'react-router-dom';
import './MobileTopBar.scss';

const MobileTopBar = ({ pageLabel }) => {
    const dispatch = useDispatch();
    const { transitionType, collapsedForm, hiddenForm } = useSelector((state) => state.sidebar);
    const location = useLocation(); // Get current location

    const rootPath = location?.pathname === '/parker/' || location?.pathname === '/parker';

    return (
        <div
            className={`mobile-topbar ${rootPath ? 'inverted-border-radius-left inverted-border-radius-right mobile-topbar-min-width height-expand fixed-position-parker-home' : ''}`}
        >
            {transitionType === 'collapse/expand' && (
                <div className="topbar-left-items">
                    {/* <img className={`logo ${collapsedForm ? 'hide-logo' : 'show-logo'}`} src={logo} alt="brand-logo" /> */}
                    <img
                        src={menuIcon}
                        className={`menu-toggler menu-mobile-size ${collapsedForm ? 'remove-margin' : 'add-margin'}`}
                        onClick={() => dispatch(changeCollapsedForm(!collapsedForm))}
                    />
                </div>
            )}
            <div className={pageLabel ? 'w-100' : ''}>
                {transitionType === 'hide/show' && (
                    <div className="topbar-left-items">
                        <img
                            src={menuIcon}
                            className={'menu-toggler menu-mobile-size remove-margin'}
                            onClick={() => dispatch(changeHiddenForm(!hiddenForm))}
                        />
                    </div>
                )}
                {pageLabel && (
                    <div>
                        <p className="page-label">{pageLabel}</p>
                        <div className="d-flex justify-content-end w-100" style={{ marginRight: '24px' }}>
                            <img src={logo} alt="img" />
                        </div>
                    </div>
                )}
            </div>

            {/* Make the logo in center on mobile */}
            {(!pageLabel || !hiddenForm) && (
                <div
                    className="d-flex justify-content-center w-100"
                    style={{
                        marginRight: location?.pathname?.startsWith('/parker/session-view-details') ? '200px' : '24px'
                    }}
                >
                    <img src={logo} alt="img" />
                </div>
            )}
        </div>
    );
};

export default MobileTopBar;
